.page_content {
    flex: 1 0 auto;
}

.page_content__inner {
    @extend %content;

    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 170px);
    min-height: rem-calc(500px);

    .page_title {
        max-width: rem-calc(770px);

        span {
            color: $colorGreen;
        }
    }

    @include for-tablet-landscape-up {
        padding-left: rem-calc(100px);
    }
}

.sections {
    display: flex;
    flex-flow: row wrap;

    @include for-tablet-portrait-up {
        margin-left: rem-calc(-60px);
    }
}

.sections__item {
    text-align: center;
    width: 50%;
    margin-bottom: rem-calc(20px);

    @include for-tablet-portrait-up {
        width: auto;
        margin-left: rem-calc(60px);
        margin-bottom: 0;
    }
}

.sections__icon {
    height: rem-calc(46px);
    margin-bottom: rem-calc(30px);
    display: flex;
    align-items: center;
    justify-content: center;

    .ic {
        fill: $colorGreen;
    }
}

.section__name {
    font-size: rem-calc(15px);
    font-weight: 700;
    line-height: rem-calc(24px);
    text-transform: uppercase;
    letter-spacing: 1.7px;

    @include for-tablet-portrait-up {
        font-size: rem-calc(18px);
        line-height: rem-calc(30px);
        letter-spacing: 2.7px;
    }
}