/* stylelint-disable */
.ic {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
  position: relative;
}

.ic--branding {
  height: 39px;
  width: 38px;
}

.ic--ecommerce {
  height: 46px;
  width: 37px;
}

.ic--logo {
  height: 17px;
  width: 32px;
}

.ic--love {
  height: 14px;
  width: 17px;
}

.ic--saas {
  height: 44px;
  width: 54px;
}

.ic--web {
  height: 45px;
  width: 44px;
}

/* stylelint-enable */
html {
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Proxima Nova W08 Xbd", Helvetica, Arial, sans-serif;
  min-height: 100%;
  background-color: #ffffff;
  color: #313639;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

audio,
canvas,
img,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.strike,
del {
  text-decoration: line-through;
}

.full_width {
  width: 100%;
}

.nomarker {
  margin: 0;
  padding: 0;
}

.nomargin {
  margin-bottom: 0;
}

.center {
  text-align: center;
}

img.left,
img.float_left {
  float: left;
  margin: 5px 28px 5px 0;
}

img.right,
img.float_right {
  float: right;
  margin: 5px 0 5px 28px;
}

figure.float_left {
  margin: 0 20px 20px 0;
}

figure.float_right {
  margin: 0 0 20px 20px;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

::-webkit-input-placeholder {
  color: #c4c4c4;
}

:-moz-placeholder,
::-moz-placeholder {
  color: #c4c4c4;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #c4c4c4;
}

input::-ms-clear {
  display: none;
}

.page_header__inner, .page_footer__inner, .page_content__inner {
  max-width: 75rem;
  margin: 0 auto;
  padding: 0 10px;
}

h1,
.page_title {
  font-family: "Proxima Nova W08 Xbd", Helvetica, Arial, sans-serif;
  font-size: 1.875rem;
  line-height: 2.75rem;
  font-weight: bold;
  color: #313639;
  margin: 0 0 1.875rem;
}

@media (min-width: 600px) {
  h1,
  .page_title {
    font-size: 3.875rem;
    line-height: 5.125rem;
    margin-bottom: 5.125rem;
  }
}

h2 {
  font-family: "Proxima Nova W08 Xbd", Helvetica, Arial, sans-serif;
  font-size: 3.25rem;
  line-height: 3.75rem;
  font-weight: bold;
  margin: 0 0 0.9375rem;
}

h3 {
  font-family: "Proxima Nova W08 Xbd", Helvetica, Arial, sans-serif;
  font-size: 1.875rem;
  line-height: 1.875rem;
  font-weight: bold;
  margin: 0 0 0.875rem;
}

h4 {
  font-family: "Proxima Nova W08 Xbd", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: bold;
  margin: 0 0 0.875rem;
}

h5 {
  font-family: "Proxima Nova W08 Xbd", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: normal;
  margin: 0 0 0.625rem;
}

h6 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0.625rem;
}

p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0 1.25rem;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ol {
  list-style-position: outside;
  padding: 0 0 0 20px;
  margin: 0 0 20px;
}

li {
  font-size: 14px;
  line-height: 20px;
}

a {
  color: #313639;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
}

a[href^='tel:'] {
  color: currentColor;
}

a[href^='tel:']:hover {
  text-decoration: none;
  color: currentColor;
}

a[href^='mailto:'] {
  color: currentColor;
}

a[href^='mailto:']:hover {
  text-decoration: none;
  color: currentColor;
}

a:hover {
  color: #21e41b;
}

a.active {
  color: #313639;
}

.pseudo_link {
  color: #313639;
  border-bottom: 1px dotted #313639;
  transition: all .15s linear;
}

.pseudo_link:hover {
  border-bottom-color: transparent;
  text-decoration: none;
}

:focus,
a:focus {
  outline: none;
}

@media (min-width: 1400px) {
  .page_title {
    margin-bottom: 2.75rem;
  }
}

.page_header {
  padding-top: 1.25rem;
}

@media (min-width: 600px) {
  .page_header {
    padding-top: 3.125rem;
  }
}

.page_footer {
  padding-bottom: 1.25rem;
}

@media (min-width: 600px) {
  .page_footer {
    padding-bottom: 3.125rem;
  }
}

.page_footer__inner {
  display: flex;
  justify-content: space-between;
}

.contact,
.slogan {
  color: #313639;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

@media (min-width: 600px) {
  .contact,
  .slogan {
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
}

.slogan .ic {
  fill: #21e41b;
}

.page_content {
  flex: 1 0 auto;
}

.page_content__inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 170px);
  min-height: 31.25rem;
}

.page_content__inner .page_title {
  max-width: 48.125rem;
}

.page_content__inner .page_title span {
  color: #21e41b;
}

@media (min-width: 900px) {
  .page_content__inner {
    padding-left: 6.25rem;
  }
}

.sections {
  display: flex;
  flex-flow: row wrap;
}

@media (min-width: 600px) {
  .sections {
    margin-left: -3.75rem;
  }
}

.sections__item {
  text-align: center;
  width: 50%;
  margin-bottom: 1.25rem;
}

@media (min-width: 600px) {
  .sections__item {
    width: auto;
    margin-left: 3.75rem;
    margin-bottom: 0;
  }
}

.sections__icon {
  height: 2.875rem;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sections__icon .ic {
  fill: #21e41b;
}

.section__name {
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1.7px;
}

@media (min-width: 600px) {
  .section__name {
    font-size: 1.125rem;
    line-height: 1.875rem;
    letter-spacing: 2.7px;
  }
}
