html {
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    font-size: $rem-base;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: $font;
    min-height: 100%;
    background-color: $colorWhite;
    color: $colorDefault;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

audio,
canvas,
img,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.strike,
del {
    text-decoration: line-through;
}

.full_width {
    width: 100%;
}

.nomarker {
    margin: 0;
    padding: 0;
}

.nomargin {
    margin-bottom: 0;
}

.center {
    text-align: center;
}

img.left,
img.float_left {
    float: left;
    margin: 5px 28px 5px 0;
}

img.right,
img.float_right {
    float: right;
    margin: 5px 0 5px 28px;
}

figure.float_left {
    margin: 0 20px 20px 0;
}

figure.float_right {
    margin: 0 0 20px 20px;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

::-webkit-input-placeholder {
    color: $colorPlaceholder;
}

:-moz-placeholder,
::-moz-placeholder {
    color: $colorPlaceholder;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $colorPlaceholder;
}

input::-ms-clear {
    display: none;
}

%clearfix {
    &:after {
        display: block;
        content: '';
        clear: both;
    }
}

%content {
    max-width: rem-calc(1200px);
    margin: 0 auto;
    padding: 0 10px;
}
