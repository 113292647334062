/* stylelint-disable */
.ic {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: middle;
    position: relative;
}

.ic--branding {
    height: 39px;
    width: 38px;
}
.ic--ecommerce {
    height: 46px;
    width: 37px;
}
.ic--logo {
    height: 17px;
    width: 32px;
}
.ic--love {
    height: 14px;
    width: 17px;
}
.ic--saas {
    height: 44px;
    width: 54px;
}
.ic--web {
    height: 45px;
    width: 44px;
}
/* stylelint-enable */