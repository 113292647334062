.page_header {
    padding-top: rem-calc(20px);

    @include for-tablet-portrait-up {
        padding-top: rem-calc(50px);
    }
}

.page_header__inner {
    @extend %content;
}