h1,
%likeh1 {
    font-family: $font;
    font-size: rem-calc(30px);
    line-height: rem-calc(44px);
    font-weight: bold;
    color: $colorDefault;
    margin: 0 0 rem-calc(30px);

    @include for-tablet-portrait-up {
        font-size: rem-calc(62px);
        line-height: rem-calc(82px);
        margin-bottom: rem-calc(82px);
    }
}

h2,
%likeh2 {
    font-family: $font;
    font-size: rem-calc(52px);
    line-height: rem-calc(60px);
    font-weight: bold;
    margin: 0 0 rem-calc(15px);
}

h3,
%likeh3 {
    font-family: $font;
    font-size: rem-calc(30px);
    line-height: rem-calc(30px);
    font-weight: bold;
    margin: 0 0 rem-calc(14px);
}

h4,
%likeh4 {
    font-family: $font;
    font-size: rem-calc(18px);
    line-height: rem-calc(30px);
    font-weight: bold;
    margin: 0 0 rem-calc(14px);
}

h5 {
    font-family: $font;
    font-size: rem-calc(16px);
    line-height: rem-calc(28px);
    font-weight: normal;
    margin: 0 0 rem-calc(10px);
}

h6 {
    font-size: rem-calc(16px);
    font-weight: bold;
    margin: 0 0 rem-calc(10px);
}

p {
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    margin: 0 0 rem-calc(20px);

    &:last-child {
        margin-bottom: 0;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ol {
    list-style-position: outside;
    padding: 0 0 0 20px;
    margin: 0 0 20px;
}

li {
    font-size: 14px;
    line-height: 20px;
}

a {
    color: $colorLink;
    transition: color $animationTime $animationFunc, background-color $animationTime $animationFunc, box-shadow $animationTime $animationFunc;
    text-decoration: none;

    &[href^='tel:'] {
        color: currentColor;

        &:hover {
            text-decoration: none;
            color: currentColor;
        }
    }

    &[href^='mailto:'] {
        color: currentColor;

        &:hover {
            text-decoration: none;
            color: currentColor;
        }
    }

    &:hover {
        color: $colorLinkHover;
    }

    &.active {
        color: $colorDefault;
    }
}

.pseudo_link {
    color: $colorLink;
    border-bottom: 1px dotted $colorLink;
    transition: all .15s linear;

    &:hover {
        border-bottom-color: transparent;
        text-decoration: none;
    }
}

:focus,
a:focus {
    outline: none;
}

.page_title {
    @extend %likeh1;

    @include for-big-desktop-up {
        margin-bottom: rem-calc(44px);
    }
}