$colorBlack: #313639;
$colorGreen: #21e41b;
$colorWhite: #ffffff;

$colorLink: $colorBlack;
$colorLinkHover: $colorGreen;

$colorDefault: $colorBlack;
$colorPlaceholder: #c4c4c4;

$font: 'Proxima Nova W08 Xbd', Helvetica, Arial, sans-serif;
$fontOpenSans: 'Open Sans', Helvetica, Arial, sans-serif;

$animationTime: .15s;
$animationFunc: ease-in-out;

// media queries

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-phone-landscape-only {
    @media (min-width: 400px) and (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-tablet-portrait-only {
    @media (min-width: 600px) and (max-width: 899px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin for-tablet-landscape-only {
    @media (min-width: 900px) and (max-width: 1199px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-desktop-only {
    @media (min-width: 1200px) and (max-width: 1399px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1400px) {
        @content;
    }
}
