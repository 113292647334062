.page_footer {
    padding-bottom: rem-calc(20px);

    @include for-tablet-portrait-up {
        padding-bottom: rem-calc(50px);
    }
}

.page_footer__inner {
    @extend %content;
    display: flex;
    justify-content: space-between;
}

.contact,
.slogan {
    color: $colorDefault;
    font-size: rem-calc(14px);
    font-weight: 700;
    line-height: rem-calc(20px);

    @include for-tablet-portrait-up {
        font-size: rem-calc(18px);
        line-height: rem-calc(30px);
    }
}

.slogan {
    .ic {
        fill: $colorGreen;
    }
}
